* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
}

#root {
  height: 90%;
}


.loginContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.btn-submit {
  width: 100%;
  background-color: #fff;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  margin-bottom: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  border: 2px solid #3ba58b;
}

.btn-submit:hover {
  background-color: #3ba58b;
  color: #fff;
}

.btn:active,
.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.dropdown-toggle.btn-secondary:focus {
  box-shadow: 0 0 0 0.25rem hsl(208deg 6% 54% / 50%);
}

.btn-secondary:not([disabled]):not(.disabled):hover {
  color: #fff !important;
  background-color: #868e96 !important;
  border-color: #868e96 !important;
}

.btn-light:not([disabled]):not(.disabled):hover {
  color: #111 !important;
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important;
}